@media only screen and (max-width: 420px) {
    .img-not-found {
        width: 280px;
    }
}

@media only screen and (max-width: 1023px) and (min-width: 421px) {
    .img-not-found {
        width: 400px;
    }
}

@media only screen and (min-width: 1024px) {
    .img-not-found {
        width: 1000px;
    }
}

.page-not-found {
    background-color: #37AA9C;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}