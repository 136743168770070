html,
body {
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased !important;
}

.button {
  font-family: 'Poppins', sans-serif !important;
  border-radius: 10px;
  padding: 0.3rem 1rem;
  text-align: center;
  text-decoration: none;
  transition-duration: 0.4s;
  cursor: pointer;
  outline: none;
  transition: all ease-in;
}

.btn-secondary {
  background-color: #354649;
  color: #fff;
  border: none;
  font-size: 1.1em;
  font-weight: 300;
}

.btn-yellow {
  background-color: #37aa9c;
  color: #e3d985;
  border: 2px solid #e3d985;
  font-size: 1.1em;
  font-weight: 300;
  width: 100%;
}

.paddingInputs {
  margin: 0 0 40px 0;
}

.errorMessage {
  color: #fff1d1fa;
  font-size: 0.8rem;
  font-weight: 400;
  text-align: end;
  margin-top: 4px;
  margin-bottom: 0;
  margin-right: 1px;
}

input[type='text'],
input[type='password'],
select,
.formStyle {
  color: #fff;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  height: 2rem;
  width: 98%;
  font-size: 16px;
  font-weight: 400;
  /* margin: 0 0 1.5rem 0; */
  padding-left: 0.5rem;
  box-shadow: none;
  box-sizing: content-box;
}

input[type='text']:focus,
input[type='password']:focus,
select:focus,
.formStyle:focus {
  border-bottom: 1px solid #fff;
  -webkit-box-shadow: 0 1px 0 0 #fff;
  box-shadow: 0 1px 0 0 #fff;
}

::placeholder {
  color: #fff;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #fff;
}

::-ms-input-placeholder {
  color: #fff;
}

a:link {
  text-decoration: none !important;
  color: #354649;
}

a:visited {
  text-decoration: none !important;
  color: #354649 !important;
}

a:hover {
  text-decoration: none !important;
  color: #354649 !important;
}

a:active {
  text-decoration: none !important;
  color: #354649 !important;
}

*:focus {
  outline-style: none;
}
