@media only screen and (max-width: 450px) {
    .sobre {
        background-color: #37AA9C;
        display: grid;
        justify-items: center;
    }
    .right {
        width: 85%;
    }
    .img-sobre {
        width: 120px;
    }
    .sobre .titulo {
        font-size: 35px;
        color: #E3D985;
        margin-bottom: 0;
        text-align: center;
        padding-top: 40px;
    }
    .sobre .subtitulo {
        font-size: 16px;
        color: #fff;
        text-align: center;
        padding-bottom: 30px;
        line-height: normal;
        line-height: normal;
    }
    .sobre .md {
        display: none;
    }
    .sobre .sm {
        display: grid;
        justify-items: center;
    }
}

@media only screen and (max-width: 1023px) and (min-width: 451px) {
    .sobre {
        background-color: #37AA9C;
        display: grid;
        justify-items: center;
    }
    .right {
        width: 60%;
    }
    .img-sobre {
        width: 250px;
    }
    .sobre .titulo {
        font-size: 35px;
        color: #E3D985;
        margin-bottom: 0;
        text-align: center;
        padding-bottom: 40px;
        padding-top: 40px;
    }
    .sobre .subtitulo {
        font-size: 15px;
        color: #fff;
        text-align: justify;
        padding-bottom: 80px;
    }
    .sobre .md {
        display: none;
    }
    .sobre .sm {
        display: grid;
        justify-items: center;
    }
}

@media only screen and (min-width: 1024px) {
    .sobre {
        background-color: #37AA9C;
        height: 100vh;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    .right {
        width: 45%;
    }
    .img-sobre {
        width: 250px;
    }
    .sobre .titulo {
        font-size: 50px;
        color: #E3D985;
        margin-bottom: 0;
    }
    .sobre .subtitulo {
        font-size: 18px;
        color: #fff;
        text-align: justify;
        line-height: normal;
    }
    .sobre .sm {
        display: none;
    }
    .sobre .md {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
}